import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import {Capacitor} from '@capacitor/core'

import {useUserStore} from '@/stores'

import authRoutes from '@/views/Auth/routes'
import appRoutes from '@/views/App/routes'
import adminRoutes from '@/views/Admin/routes'
import promptRoutes from '@/views/Prompts/routes'
import messagingRoutes from '@/views/Messaging/routes'
import opportunityRoutes from '@/views/Opportunities/routes'
import {getActivityRoutes} from '@/views/Activity/routes'
import {getPageRoutes} from '@/views/Pages/routes'
import {getTopicRoutes} from '@/views/Topics/routes'

const isNative = Capacitor.isNativePlatform();
const routes = [authRoutes, getActivityRoutes(isNative), appRoutes, adminRoutes, promptRoutes, messagingRoutes, getPageRoutes(isNative), getTopicRoutes(isNative), opportunityRoutes].flat();
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) { return { top: 0 } },
});

router.beforeEach((to, from, next) => {
  let user = useUserStore().user;
  if(to.matched.some(record => record.meta.requiresStaff)) {
    if (user && user.authenticated && user.roles.staff) {
      next();
    } else next('/login');
  } else if(to.matched.some(record => record.meta.requiresAuth)) {
    if (user && user.authenticated) {
      next();
    } else next('/login');
  } else next();
  return;
});

export default router;