export default [
    {
        name: 'admin',
        path: '/admin',
        meta: { requiresAuth: true}, 
        component: () => import('./View.vue')
    },
    {
        name: 'edit_user',
        path: '/admin/edit/:uid?',
        meta: { requiresStaff: true},
        component: () => import('./EditUser.vue')
    }
]