export const getTopicRoutes = isNative => [
    {
        name: 'topics',
        path: (isNative ? '/' : '/topics'),
        meta: { requiresAuth: true},
        component: () => import('./TopicListView.vue')
    },
    {
        name: 'threads',
        path: (isNative ? '/:topic_id' : '/topics/:topic_id'),
        meta: { requiresAuth: true},
        component: () => import('./ThreadListView.vue')
    },
    {
        name: 'thread',
        path: '/thread/:id',
        meta: { requiresAuth: true},
        component: () => import('./ThreadView.vue')
    }

]