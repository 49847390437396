export default [
    {
        name: 'login',
        path: '/login',
        component: () => import('./LoginView.vue')
    },
    {
        name: 'logout',
        path: '/logout',
        meta: { requiresAuth: true },
        component: () => import('./LogoutView.vue')
    },
    {
        name: 'lost_password',
        path: '/lost_password',
        component: () => import('./LostPasswordView.vue')
    },
    {
        name: 'reset_password',
        path: '/reset_password/:prrid',
        component: () => import('./ResetPasswordView.vue')
    }
];
