export default [
    {
        name: 'prompt-location',
        path: '/prompt/location',
        meta: { requiresAuth: true},
        component: () => import('./LocationPromptView.vue')
    },
    {
        name: 'prompt-notifications',
        path: '/prompt/notifications',
        meta: { requiresAuth: true},
        component: () => import('./NotificationPromptView.vue')
    }
]
