export default [
    {
        name: 'messaging',
        path: '/messages',
        meta: { requiresAuth: true}, 
        meta: { requiresAuth: true}, 
        component: () => import('./ChannelListView.vue')
    },
    {
        name: 'channel',
        path: '/messages/::channel_id',
        meta: { requiresAuth: true}, 
        component: () => import('./ChannelView.vue')
    }
]