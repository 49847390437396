export default [
    {
        name: 'opportunities',
        path: '/opportunities',
        meta: { requiresAuth: true},
        component: () => import('./OpportunityListView.vue')
    },
    {
        name: 'opportunity',
        path: '/opportunities/:id',
        meta: { requiresAuth: true},
        component: () => import('./OpportunityView.vue')
    },
    {
        name: 'new-opportunity',
        path: '/opportunities/new',
        meta: { requiresAuth: true},
        component: () => import('./NewOpportunityView.vue')
    }
]