import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

import {Capacitor} from '@capacitor/core';
import {PushNotifications} from '@capacitor/push-notifications';
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';

export const useNotifications = defineStore('notifications', () => {

    const _native = Capacitor.isNativePlatform();
    const _platform = Capacitor.getPlatform();
    const _granted = ref(false);

    async function checkPermissions() {
        if(_native) {
            const status = await PushNotifications.checkPermissions();
            _granted.value = status.receive === 'granted';
        }
        return _granted.value;
    }
    
    const platform = computed(() => _platform.value);
    
    const granted = computed(() => {
        checkPermissions();
        return _granted.value;
    });

    async function register() {
        if(_native && !_granted.value) {
          const permission = await PushNotifications.requestPermissions();
          if(permission.receive === 'granted') await PushNotifications.register();
          else return false;
        }
        return _granted.value;
    }

    const handleEvents = (func) => _native ? PushNotifications.addListener('pushNotificationActionPerformed', func) : null;
    const openSettings = () => _native ? NativeSettings.openIOS({option: IOSSettings.App,}) : null;
    
    return {granted, platform, handleEvents, checkPermissions, openSettings, register};
})