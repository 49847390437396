export const getPageRoutes = isNative => [
    {
        name: 'landing',
        path: (isNative ? '/landing/parking' : '/'),
        beforeEnter() {
            window.location.href = 'https://www.locational.app';
        }
        //component: () => import('./LandingView.vue')
    },
    {
        name: 'about',
        path: '/about',
        component: () => import('./AboutView.vue')
    },
    {
        name: 'tos',
        path: '/tos',
        component: () => import('./ToSView.vue')
    },
    {
        name: 'privacy',
        path: '/privacy',
        component: () => import('./PrivacyView.vue')
    },
    {
        name: 'support',
        path: '/support',
        component: () => import('./SupportView.vue')
    },
    {
        name: 'payment',
        path: '/subscribe/:iid',
        component: () => import('./PaymentView.vue')
    },
]