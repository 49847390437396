import { ref } from 'vue'
import { defineStore } from 'pinia'

import {User} from '@/foundation'

export const useUserStore = defineStore('user', () => {

  const user = ref(User.fromSavedCredentials());

  async function login(username, password) {
    user.value = await User.loginUser(username, password);
    return user.value;
  }

  async function requestPasswordReset(email) {
    await User.requestPasswordReset(email);
  }

  async function resetPassword(id, password1, password2) {
    return await User.resetPassword(id, password1, password2);
  }

  async function getUser(id) {
    if(typeof id === 'undefined') id = user.value.id;
    return await user.value.getContact(id);
  }

  return {user, login, requestPasswordReset, resetPassword, getUser}
})
