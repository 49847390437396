export default [
    {
        name: 'register',
        path: '/register/:iid',
        component: () => import('./RegistrationView.vue')
    },
  
    {
        name: 'associates',
        path: '/associates',
        meta: { requiresAuth: true },
        component: () => import('./AssociateView.vue')
    },
    {
        name: 'invitations',
        path: '/invite',
        meta: { requiresAuth: true },
        component: () => import('./InvitationView.vue')
    },
    {
        name: 'search',
        path: '/search/:query',
        meta: { requiresAuth: true },
        component: () => import('./SearchView.vue')
    },
    {
        name: 'profile',
        path: '/profile/:uid?',
        meta: { requiresAuth: true},
        component: () => import('./ProfileView.vue')
    },
    {
        name: 'settings',
        path: '/settings',
        meta: { requiresAuth: true},
        component: () => import('./SettingsView.vue')
    },
    {
        name: 'planner',
        path: '/planner',
        meta: { requiresAuth: true},
        component: () => import('./PlannerView.vue')
    }
]