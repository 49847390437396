import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createVuetify } from 'vuetify'
import * as Sentry from '@sentry/vue'

import App from './App.vue'
import router from './router'

// Vuetify
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import {Capacitor} from '@capacitor/core'

const app = createApp(App);
const pinia = createPinia();
const vuetify = createVuetify({components, directives, theme:{defaultTheme: 'light'}});

app.config.isNative = Capacitor.isNativePlatform();

/*Sentry.init({
    app,
    dsn: 'https://e7e5db9d2bedf9f88e12c54a531837df@o4505850776715264.ingest.sentry.io/4505878554673152',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/app\.locational\.app/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });*/

app.use(pinia);
app.use(router);
app.use(vuetify);
app.mount('#app');