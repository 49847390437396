import Foundation from './foundation'

import {getSettings} from '@/config'
const settings = getSettings();

export default class FoundationService {
    cache_key = 'foundation-cache'

    constructor(api) {
        this._api = api;
        this._data = [];
    }
    
    marshall (data_str) {  if(data_str) this._data = JSON.parse(data_str); }
    serialize () { return JSON.stringify(this._data); }
    _set_cache() { localStorage.setItem(this.cache_key, this.serialize()); }
    _get_cache() { this.marshall(localStorage.getItem(this.cache_key)) }
    
    static fromSavedCredentials() {
        let credentials = localStorage.getItem(settings.auth_cache);
        if(credentials && (credentials = JSON.parse(credentials))) {
            let api = new Foundation(settings.foundation, credentials);
            let instance = new this(api);
            instance._get_cache();
            return instance;
        }
        return null;
    }
    
    fromData(data) {
        let instance = new this.constructor(this._api);
        instance._data = data;
        return instance;
    }
    
    async refresh(cached=true) {
        this._data = await this.fetchData();
        if(cached) this._set_cache();
    }

    async fetchData() {
        return this.getChannels()
    }

}
