
<template>
  <v-app id="locational">
    <Suspense>
      <RouterView />
    </Suspense>
  </v-app>
</template>

<script setup>
  import { onMounted } from 'vue';
  import { RouterView, useRouter } from 'vue-router';
  import { useNotifications } from '@/stores/notifications';

  const router = useRouter();
  const notifications = useNotifications()

  notifications.handleEvents(event => {
    let channel_id = event.notification.data.aps['thread-id'];
    if(channel_id) {
      let components = channel_id.split('/');
      if(components.length > 1) router.push({name:components[0], params:{id:components[1]}});
      else router.push({name:'channel', params:{channel_id}});
    }
  });

  onMounted(() => {document.addEventListener('visibilitychange', () => notifications.checkPermissions())});

</script>

<style>
</style>



